<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header position-fixed">
      <h5 id="offcanvasRightLabel"></h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="row h-100 align-content-center">
        <div class="col-12 h-50 d-flex justify-content-center align-items-center flex-column">
          <div class="row">
            <div class="col-6 text-center m-auto pb-3">
              <img src="/img/ui-theme/metaluxe-contact.png" alt="Colliers" class="rounded-circle img-fluid">
            </div>
            <div class="col-12 text-center">
              <h4 class="text-black">Contact Simon Beirne</h4>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-6 col-sm-4 m-auto d-flex justify-content-evenly">
              <a href="mailto:Simon.Beirne@colliers.com">
                <img src="/img/ui-theme/email-icon-rev.svg" alt="Email">
              </a>
              <a href="tel:+61 413 765 098" class="d-lg-none">
                <img src="/img/ui-theme/phone-icon-rev.svg" alt="Contact">
              </a>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-6 col-sm-4 m-auto d-flex justify-content-evenly d-none d-lg-block text-center p-2">
              <a href="tel:+61 413 765 098" class="phone">
                <h4>+61 413 765 098</h4>
              </a>
            </div>
          </div>
          <!-- <div class="row w-100">
            <div class="col-12 py-4 text-center text-black">
              <h3>Or</h3>
            </div>
          </div>
          <div class="row w-100">
            <div class="col-12 pt-3 text-center text-black">
              <h4>Remi Gamble will contact you</h4>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OffCanvas',
  props: [
    'heading'
  ],
  data() {
    return {
      
    }
  }
};
</script>

<style scoped>
.btn-theme-menu {
  width: 60%;
  background: #000;
  color: #fff;
  font-weight: 300;
  margin: auto;
}
.phone {
  color: #000;
  text-decoration: none;
}
</style>