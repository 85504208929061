import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "840bd4a4-75e7-4c64-9254-5770dfd4901c",
    videoLibraryId: "112966",
    streamCdnUrl: "https://vz-a215a777-f03.b-cdn.net",
    streamApiKey: "93498e00-d088-412e-af7df3480579-c673-4361",
    storageApiPassword: "871db109-cd9e-408c-a13eaaadd497-cb00-4be6",
    cdnUrl: "https://urbanquarter.b-cdn.net",
    devFolder: "/urbanquarter-pwa/",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
    
    // top level menu
    assetsMenuOrder: [
        'Presenting',
        'News Corp Office',
        'Urban Quarter',

    ],
    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],
  
    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'tour 1'
        ],
        panoramasUrl: [
            'https://kuula.co/share/collection/7FnDP?logo=0&info=0&fs=1&vr=1&sd=1&gyro=0&initload=0&thumbs=1'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };