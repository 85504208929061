<template>
  <!-- Top navigation -->
  <DetailTopNav :heading="this.viewType" :subheading="this.assetType" />

  <!-- Range -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1"
    v-if="viewType == 'range' || viewType == 'renders' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName"
            class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1"
    v-if="viewType == 'floorplans' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName"
            class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1"
    v-if="viewType == 'panoramas' || viewType == 'virtual-tour' || viewType == 'balcony-views' && this.assetTypesReady === true">
    <iframe :src="this.assetTypes[this.order].url" :title="this.assetTypes[this.order].title" width="100%"
      height="100%"></iframe>
  </div>

  <!-- Brochures -->
  <div id="pdf-view-inline"></div>

  <OffCanvas />
</template>

<script>
import DetailTopNav from '@/components/DetailTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    DetailTopNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null,
      assetNames: null,
      assetUrls: null,
      componentName: "AssetView",
      assetTypesReady: false,
      adobeApiPDFReady: false,
      adobeDCView: null,
    }
  },
  created() {
    if (this.assetType == 'Presenting' || this.assetType === 'news-corp-office' || this.assetType === 'urban-quarter') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed1Asset.videos
        this.assetNames = this.theme.bed1Asset.videosName
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed1Asset.floorplans
        this.assetNames = this.theme.bed1Asset.floorplansName
        this.assetUrls = this.theme.bed1Asset.floorplansUrl
      } else if (this.viewType === 'panoramas' || this.viewType === 'virtual-tour' || this.viewType === 'virtual-tours') {
        this.assetOrder = this.theme.bed1Asset.panoramas
        this.assetNames = this.theme.bed1Asset.panoramasName
        this.assetUrls = this.theme.bed1Asset.panoramasUrl
      } else if (this.viewType === 'balcony-views') {
        this.assetOrder = this.theme.bed1Asset.balcony
        this.assetNames = this.theme.bed1Asset.balconyName
        this.assetUrls = this.theme.bed1Asset.balconyUrl
      } else if (this.viewType === 'renders') {
        this.assetOrder = this.theme.bed1Asset.renders
        this.assetNames = this.theme.bed1Asset.rendersName
        this.assetUrls = this.theme.bed1Asset.rendersUrl
      } else if (this.viewType === 'range') {
        this.assetOrder = this.theme.bed1Asset.range
        this.assetNames = this.theme.bed1Asset.rangeName
        this.assetUrls = this.theme.bed1Asset.rangeUrl
      } else if (this.viewType === 'pdf') {
        this.assetOrder = this.theme.bed1Asset.pdf
        this.assetNames = this.theme.bed1Asset.pdfName
        this.assetUrls = this.theme.bed1Asset.pdfUrl
      }
    }

    BunnyNetService.getRenders(this.folderStructure, this.assetType)
      .then(response => {
        let j = 0;
        // Remap array

        console.log(response.data);

        const menuItems = response.data.map((item) => {
          if (this.assetNames && this.assetNames.length >= 1) {
            return {
              Guid: item.Guid,
              ObjectName: unslugify(item.ObjectName),
              LinkName: item.ObjectName,
              Path: item.Path,
              IsDirectory: item.IsDirectory,
              StorageZoneName: item.StorageZoneName,
              title: this.assetNames[j],
              url: this.assetUrls[j],
              id: j++
            };
          } else {
            return {
              Guid: item.Guid,
              ObjectName: unslugify(item.ObjectName),
              LinkName: item.ObjectName,
              Path: item.Path,
              IsDirectory: item.IsDirectory,
              StorageZoneName: item.StorageZoneName,
              title: unslugify(item.ObjectName.split('.').slice(0, -1).join(".")).replace("hyphen", "-"),
              url: [],
              id: j++
            };
          }
        });

        // Custom order array based on section and type
        if (this.assetOrder && this.assetOrder.length >= 1) {
          this.assetTypes = this.reorder(menuItems, this.assetOrder);
        } else {
          this.assetTypes = menuItems
        }

        this.assetTypesReady = true
        if (this.viewType == "pdf") {
          setTimeout(() => {
            if (this.adobeApiPDFReady == true) {
              console.log("It is ready")
              console.log(this.assetUrls[this.order])
              this.initialisePdf(this.assetUrls[this.order], this.assetNames[this.order])
              // this.initialisePdf("https://developmentprog.com/pdf1.pdf", "Florian Brochure")
            }
          },
            "1000"
          )
        }
      })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function (data_array, real_order) {
      var i, d = {}, result = [];
      for (i = 0; i < data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for (i = 0; i < real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },
    initialisePdf: function (pdfLink, pdfName) {
      this.adobeDCView.previewFile({
        content: { location: { url: pdfLink } },
        metaData: { fileName: pdfName },
      }, { embedMode: "IN_LINE", showDownloadPDF: false, showPrintPDF: false });
    },
  },
  setup() {
    const { theme } = global

    return {
      theme
    }
  },
  mounted() {
    document.addEventListener("adobe_dc_view_sdk.ready", () => {
      this.adobeApiPDFReady = true;
      console.log("Adobe created with adobe_dc_view_sdk.ready");
    });

    // Dynamically load Adobe SDK Viewer for this page
    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "https://documentcloud.adobe.com/view-sdk/viewer.js"
    );
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  watch: {
    adobeApiPDFReady(val) {
      if (val) {
        let view = new window.AdobeDC.View({
          clientId: "d61f95f324b548daa6555fad9f3e09f3",
          divId: "pdf-view-inline",
        });
        this.adobeDCView = Object.freeze(view);
        console.log("Adobe is mounted with Client ID");
      }
    },
  }
}
</script>